// src/components/SideBar/SideBar.js
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useMobile } from "../../contexts/MobileContext";
import { useDeleteThread } from "../../hooks/useDeleteThread";
import { useGetThreadsByUser } from "../../hooks/useGetThreadsByUser";
import { filterByCourse } from "../../utils/filterByCourse";
import CourseSelector from "../CourseSelector";
import ConfirmDeleteThreadDialog from "./confirmDeleteThreadDialog";

const SideBar = ({
  drawerOpen,
  drawerWidth,
  selectedCourse,
  handleCourseChange,
  coursesData,
  onThreadClick,
  onStartQuizClick,
  currentThread,
  theme,
  setCourseThreads,
  isAdmin,
  inAdmin,
  getStudentsInCourse,
  selectedStudent,
  setSelectedStudent,
  fetchThreadsForCourse,
  handleCourseStats,
  handleStudentChange,
  setCurrentThread,
  setUserThreads,
  userThreads,
  user,
  setMessages,
  handleRenameThread,
  handleDeleteThread
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedThread, setSelectedThread] = useState(null);
  const [editingThreadId, setEditingThreadId] = useState(null);
  const [editedName, setEditedName] = useState("");
  const deleteThread = useDeleteThread(
    setCurrentThread,
    setUserThreads,
    setMessages
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filteredThreads, setFilteredThreads] = useState([]);
  const { mutate: getThreads, isLoading, data } = useGetThreadsByUser();
  const editFieldRefs = useRef({});
  const LoadingSkeleton = () => (
    <div style={{ width: drawerWidth }}>
      <Skeleton variant="rectangular" height={60} />
      <Skeleton variant="text" height={30} style={{ margin: "10px" }} />

      <Divider />

      <List>
        {[...new Array(15)].map((_, index) => (
          <ListItemButton key={index} sx={{ padding: "10px" }}>
            <Skeleton variant="text" width="80%" height={30} />
          </ListItemButton>
        ))}
      </List>
    </div>
  );

  useEffect(() => {
    if (!isAdmin) {
      getThreads(user.userId);
    } else {
      setFilteredThreads([]);
    }
  }, [user, getThreads, userThreads, isAdmin]);

  useEffect(() => {
    setFilteredThreads([]);
    if (data) {
      const newFilteredThreads = filterByCourse(selectedCourse, data);
      console.log("newFilteredThreads: ", newFilteredThreads);
      setFilteredThreads(newFilteredThreads);
      setCourseThreads(newFilteredThreads);
    }
  }, [data, selectedCourse, menuAnchorEl]);

  useEffect(() => {}, [filteredThreads]);

  useEffect(() => {
    if (editingThreadId && editFieldRefs.current[editingThreadId]) {
      const input = editFieldRefs.current[editingThreadId];
      input.focus();
      input.select();
    }
  }, [editingThreadId]);

  const startEditing = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    handleMenuClose();

    setEditingThreadId(selectedThread.threadId);
    setEditedName(selectedThread.threadName);
  };

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleMenuClose = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setMenuAnchorEl(null);
  };

  const handleRename = (event) => {
    if (event) {
      event.stopPropagation();
    }
    handleRenameThread(selectedThread, editedName);
    setEditingThreadId(null);
    handleMenuClose();
  };

  const promptDelete = () => {
    setIsDialogOpen(true);
    handleMenuClose();
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (selectedThread && user) {
      handleDeleteThread(selectedThread);
    }
    setIsDialogOpen(false);
  };

  const isMobile = useMobile().isMobile;
  if (isLoading) {
    return (
      <Drawer
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        sx={{
          width: drawerWidth,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: theme.palette.background.default,
          },
        }}
      >
        <LoadingSkeleton drawerWidth={drawerWidth} />
      </Drawer>
    );
  } else {
    return (
      <>
        <Drawer
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: theme.palette.background.default,
            },
          }}
        >
          <CourseSelector
            setMessages={setMessages}
            coursesData={coursesData}
            selectedCourse={selectedCourse}
            handleCourseChange={handleCourseChange}
            isAdmin={isAdmin}
            getStudentsInCourse={getStudentsInCourse}
            selectedStudent={selectedStudent}
            setSelectedStudent={setSelectedStudent}
            handleCourseStats={handleCourseStats}
            fetchThreadsForCourse={fetchThreadsForCourse}
            handleStudentChange={handleStudentChange}
            onStartQuizClick={onStartQuizClick}
            setFilteredThreads={setFilteredThreads}
          />

          {!isAdmin && (
            <Typography
              style={{
                position: "relative",
                fontFamily: "Arial, sans-serif",
                textAlign: "center",
                fontSize: isMobile ? "10px" : "16px",
                fontWeight: "bold",
                backgroundColor: "theme-color",
              }}
            >
              Previous Conversations
            </Typography>
          )}

          <Divider />

          <List
            sx={{
              overflowY: "auto",
              flexGrow: 1,
              position: "relative",
            }}
          >
            {filteredThreads.map((thread, index) => (
              <ListItemButton
                key={thread.threadId}
                onClick={(event) => {
                  onThreadClick(thread, false);
                  setSelectedThread(thread);
                  event.stopPropagation();
                }}
                sx={{
                  backgroundColor:
                    index % 2 === 0
                      ? theme.palette.action.hover
                      : theme.palette.background.paper,
                  "&:hover": {
                    backgroundColor: theme.palette.action.selected,
                    "& .delete-button": {
                      visibility: "visible",
                    },
                  },
                  "&.Mui-selected, &.Mui-selected:hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  },
                  justifyContent: "space-between",
                }}
                selected={currentThread.threadId === thread.threadId}
              >
                <ListItemText
                  sx={{
                    ".MuiTypography-root": {
                      // Targets the Typography component inside ListItemText
                      display: "flex", // Enable flexbox
                      justifyContent: "space-between", // Space out the text and the button
                      alignItems: "center", // Center items vertically
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      letterSpacing: "normal",
                      fontSize: isMobile ? "10px" : "20px",
                      maxWidth: "93%", // or whatever value is appropriate
                    },
                  }}
                >
                  {editingThreadId === thread.threadId ? (
                    <TextField
                      value={editedName}
                      onChange={(e) => setEditedName(e.target.value)}
                      fullWidth
                      onBlur={handleRename}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleRename();
                        }
                      }}
                      inputRef={(el) =>
                        (editFieldRefs.current[thread.threadId] = el)
                    }
                    />
                  ) : (
                    <ListItemText
                      sx={{
                        ".MuiTypography-root": {
                          // Targets the Typography component inside ListItemText
                          display: "inherit", // Or use 'inline-block' if needed
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          letterSpacing: "normal",
                          fontSize: "20px",
                          maxWidth: "90%", // or whatever value is appropriate
                        },
                      }}
                      primary={thread.threadName}
                    />
                  )}
                  {!isAdmin && !editingThreadId && (
                    <IconButton
                      className="options-button"
                      onClick={(event) => {
                        onThreadClick(thread, false);
                        setSelectedThread(thread);
                        event.stopPropagation();
                        handleMenuOpen(event, selectedThread);
                      }}
                      edge="end"
                      aria-label="delete"
                      size="small"
                      sx={{
                        position: "absolute",
                        right: 8,

                        opacity:
                          isMobile && currentThread.threadId === thread.threadId
                            ? 1
                            : 0,
                        transition: "opacity 0.3s",
                        "&:hover": {
                          opacity: 1,
                        },
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  )}
                  <Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleMenuClose}
                    sx={{
                      boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
                    }}
                    anc
                  >
                    <MenuItem
                      onClick={(event) => startEditing(event, selectedThread)}
                    >
                      Rename
                    </MenuItem>

                    <MenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                        promptDelete(selectedThread);
                        handleMenuClose(event);
                      }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </ListItemText>
              </ListItemButton>
            ))}
          </List>
        </Drawer>
        <ConfirmDeleteThreadDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          onConfirm={handleConfirmDelete}
          title="Confirm Deletion"
          message={`Are you sure you want to delete the thread "${selectedThread?.threadName}"?`}
        />
      </>
    );
  }
};

export default SideBar;
