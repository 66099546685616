// src/components/CourseSelector/CourseSelector.js
import React, { useState, useEffect } from "react";
import { Select, MenuItem, Box, InputLabel } from "@mui/material";
import { filterByCourse } from "../../utils/filterByCourse";
import { useGetThreadsByUser } from "../../hooks/useGetThreadsByUser";

const CourseSelector = ({
  setMessages,
  coursesData,
  selectedCourse,
  handleCourseChange,
  handleCourseStats,
  onStartQuizClick,
  isAdmin,
  getStudentsInCourse,
  selectedStudent,
  setSelectedStudent,
  handleStudentChange,
  setFilteredThreads,
}) => {
  const hasCourses = coursesData.length > 0;
  const [students, setStudents] = useState([]); // State to store students
  const [open, setOpen] = React.useState(null);
  const [quizModalOpen, setQuizModalOpen] = useState(false);
  const { fetchThreadsByUser } = useGetThreadsByUser();

  const handleClick = (event) => {
    setOpen(event.currentTarget);
  };

  const handleStudentChangeClick = async (event) => {
    const studentId = event.target.value;
    if (studentId === "courseStats") {
      handleCourseStats();
      setFilteredThreads([]);
    } else {
      setSelectedStudent(studentId);
      try {
        const fetchThreadsByUserResponseData = await fetchThreadsByUser(studentId);
        const studentThreads = fetchThreadsByUserResponseData.data;
        console.log(studentThreads);
        const newFilteredThreads = filterByCourse(selectedCourse, studentThreads);
        setFilteredThreads(newFilteredThreads);
      } catch (error) {
        console.error("Error fetching threads by user:", error);
      }
    }
    setMessages(null);
  };

  // useEffect(() => {
  //   if (threads) {
  //     const newFilteredThreads = filterByCourse(selectedCourse, threads);

  //     setFilteredThreads(newFilteredThreads);
  //   }
  // }, [threads]);

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    const fetchStudents = async () => {
      if (selectedCourse && isAdmin) {
        const studentsData = await getStudentsInCourse(selectedCourse);

        setStudents(studentsData);
        setSelectedStudent("courseStats"); // Reset selected student when course changes
      }
    };

    fetchStudents();
  }, [selectedCourse, isAdmin]);

  return (
    <Box sx={{ padding: 2 }}>
      {hasCourses && (
        <>
          <Select
            value={selectedCourse}
            onChange={handleCourseChange} 
            fullWidth
            sx={{ height: "64px", marginBottom: 2 }}
            variant="outlined"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "300px",
                },
              },
            }}
          >
            {coursesData.map((course) => (
                <MenuItem key={course} value={course}>
                  {course}
                </MenuItem>
              
            ))}
          </Select>
        </>
      )}
      {isAdmin && (
        <>
          <Select
            value={selectedStudent}
            onChange={handleStudentChangeClick}
            fullWidth
            sx={{ height: "64px", marginBottom: 2 }}
            variant="outlined"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "300px",
                },
              },
            }}
          >
            {/* Default selection for overall course statistics */}
            <MenuItem value="courseStats" label="Course Statistics">Course Statistics</MenuItem>

            {/* Student specific selections */}
            {students.map((student, index) => (
              <MenuItem key={index} value={student} label={student}>
                {student}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </Box>
  );
};

export default CourseSelector;
