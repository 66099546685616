import { useCallback, useRef, useState } from "react";

/**Hook to connect to a websocket
 *
 * @param {user}
 *    @user the user object that is connecting to the websocket
 *
 * @returns {sendChatMessage,messages,setMessages,connect,disconnect,isConnected,}
 *    @sendChatMessage the function to send a chat messeage to the websocket
 *    @messages the list of message objects for the user
 *    @setMessages the function to update the state of messages
 *    @connect the function to connect to the websocket
 *    @disconnect the function to disconnect from the websocket
 *    @isConnect a bool denoting if the client is connected to the websocket
 */
export const useLiveChat = ({ user }) => {
  const socket = useRef(null);
  const [messages, setMessages] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const connect = useCallback(() => {
    if (socket.current) {
      return;
    }
    socket.current = new WebSocket(
      "wss://l7yx2pbird.execute-api.us-east-1.amazonaws.com/dev/"
    );
    socket.current.onopen = () => {
      setIsConnected(true);
      socket.current.send(
        JSON.stringify({ action: "connect", LiveChatId: user.userId })
      );
    };
    socket.current.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);
        if (
          message.text !== undefined &&
          message.fromUser !== undefined &&
          message.timestamp !== undefined
        ) {
          setMessages((prevMessages) => [...prevMessages, message]);
        } else if (Array.isArray(message)) {
          // If it's an array, set it directly to the messages state
          setMessages(message);
        }
      } catch (error) {
        console.error("Error parsing message data:", error);
      }
    };
    socket.current.onclose = (event) => {
      setIsConnected(false);
      setTimeout(() => {
        //reconnect logic here
      }, 1000); // Reconnect after 1 second
    };
    socket.current.onerror = (error) => {
      console.error("WebSocket error", error);
      if (socket.current) {
        socket.current.close();
      }
    };
  }, [user.userId]);
  const sendChatMessage = useCallback(
    ({ text }) => {
      if (!socket.current || socket.current.readyState !== WebSocket.OPEN) {
        return;
      }
      socket.current.send(
        JSON.stringify({
          action: "message",
          message: text,
          LiveChatId: user.userId,
          fromUser: true,
        })
      );
    },
    [user.userId]
  );
  const disconnect = () => {
    if (socket.current) {
      socket.current.close();
      socket.current = null;
    }
  };
  // Return the sendChatMessage function and the messages state
  return {
    sendChatMessage,
    messages,
    setMessages,
    connect,
    disconnect,
    isConnected,
  };
};
