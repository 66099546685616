import React, { createContext, useContext } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

const MobileContext = createContext();

export const MobileProvider = ({ children }) => {
  const isMobile = useMediaQuery("(max-width:720px)"); // or your preferred breakpoint

  return (
    <MobileContext.Provider value={{ isMobile }}>
      {children}
    </MobileContext.Provider>
  );
};

export const useMobile = () => useContext(MobileContext);
