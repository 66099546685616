import { useMutation } from "react-query";
import { useRef } from "react";
import { v4 as uuidv4 } from "uuid";

/**
 * Handles opening the websocket that allows communication from the Tailored Systems Assistants Service.
 *
 * @returns {websocket} - Returns a websocket to listen to for the streamed response.
 */

const openAssistantResponseWs = async () => {
  try {
    const wsId = uuidv4();
    const ws = new WebSocket(`${process.env.REACT_APP_ASSISTANT_WEBSOCKET_URL}?wsId=${wsId}`);

    ws.onopen = () => console.log('WebSocket is open');
    ws.onerror = (error) => console.log('WebSocket error: ', error);
    ws.onclose = (event) => console.log('WebSocket is closed: ', event);

    // Wait for the WebSocket to open before returning it
    await new Promise((resolve, reject) => {
      ws.onopen = resolve;
      ws.onerror = reject;
    });

    return ws; // returns the open WebSocket
  } catch (error) {
    console.error("There was an error opening the assistant's websocket:", error);
    return null;
  }
};

export const useOpenAssistantResponseWs = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation(() => openAssistantResponseWs(), {
    onSettled: (data, error) => {
      if (resolveMutation.current) {
        if (error) {
          resolveMutation.current.reject({ error });
        } else {
          resolveMutation.current.resolve(data);
        }
      }
    },
  });

  const openAssistantResponseWsWithPromise = () => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate();
    });
  };

  return { ...mutation, openAssistantResponseWs: openAssistantResponseWsWithPromise };
};