import { useCallback, useEffect, useState } from "react";

/**Grabs messages tied to a livechatId
 *
 * This hook provides an abstraction for deleting thread data from a specified users data.
 *
 *
 * @param {user, threadId}
 *    @user the user object that has the thread to be deleted
 *    @threadId a string that identifies the thread to be deleted
 * @returns {mutation}
 *    @mutation the mutated hook to be used to delete the thread.
 */

export const useGetLiveChatMessages = (LiveChatId) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://xdik00law6.execute-api.us-east-1.amazonaws.com/prod/getMessageByChat?LiveChatId=${LiveChatId}`
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      setData(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [LiveChatId]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return { data, loading, error, refetch: fetchData };
};
