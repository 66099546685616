import React, { useState } from "react";
import {
  Typography,
  Box,
  Modal,
  TextField,
  Tabs,
  Tab,
  FormGroup,
  Button
} from "@mui/material";

const LoginModal = ({
    modalOpen,
    handleCloseModal,
    tabValue,
    handleTabChange,
    showSignUp,
    accessCode,
    handleAccessCodeChange,
    handleAccessCodeSubmit,
    courseData,
    handleAccessCodeSignup,
    handlePasswordLogin,
    username,
    password,
    confirmPassword,
    handleUsernameChange,
    handlePasswordChange,
    handleConfirmPasswordChange
  }) => {
    const [usernameValidationMessage, setUsernameValidationMessage] = useState('');
    const [passwordValidationMessage, setPasswordValidationMessage] = useState('');

    // Validation logic for username and password
    const validateUsername = (value) => {
        if (value.length < 6 || value.length > 24 || /[^a-zA-Z0-9]/.test(value)) {
            setUsernameValidationMessage('Username must be 6-24 characters and cannot contain special characters.');
            return false;
        } else {
            setUsernameValidationMessage('');
            return true;
        }
    };

    const validatePassword = (passwordValue, confirmPasswordValue) => {
        // Allow letters, numbers, and specific special characters, reject others
        const passwordRegex = /^[a-zA-Z0-9!@#$%^&*()_+]{6,24}$/;
        
        if (!passwordRegex.test(passwordValue)) {
            setPasswordValidationMessage('Password must be 6-24 characters and can include these special characters: [! @ # $ % ^ & * ( ) _ +].');
            return false;
        } else if (passwordValue !== confirmPasswordValue) {
            setPasswordValidationMessage('Passwords must match.');
            return false;
        } else {
            setPasswordValidationMessage('');
            return true;
        }
    };

    const onUsernameChange = async (event) => {
        const value = event.target.value;
        await handleUsernameChange(event); // Assuming this sets the username state in a parent component
        validateUsername(value);
    };

    const onPasswordChange = async (event) => {
        const value = event.target.value;
        await handlePasswordChange(event); // Assuming this sets the password state in a parent component
        validatePassword(value, confirmPassword);
    };

    const onConfirmPasswordChange = async (event) => {
        const value = event.target.value;
        await handleConfirmPasswordChange(event); // Assuming this sets the confirmPassword state in a parent component
        validatePassword(password, value); // Call validatePassword to ensure both passwords match
    };

    const onSignup = () => {
        const validUsername = validateUsername(username);
        const validPassword = validatePassword(password, confirmPassword);
        if (validUsername && validPassword) {
            handleAccessCodeSignup(); // Proceed with signup
        }
    };

    const onLogin = () => {
        const validUsername = username.length > 0;
        const validPassword = password.length > 0;
        if (validUsername && validPassword) {
            handlePasswordLogin(); // Proceed with login
        }
    };

    return (
    <Modal open={modalOpen} onClose={handleCloseModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="login sign-in tabs">
          <Tab label="Signup" />
          <Tab label="Login" />
        </Tabs>

        {tabValue === 0 && !showSignUp && (
          <Box p={3}>
            <Typography variant="h6" gutterBottom>
              Signup with Access Code
            </Typography>
            <FormGroup>
              <TextField
                label="Access Code"
                type="text"
                fullWidth
                variant="outlined"
                margin="normal"
                value={accessCode}
                onChange={handleAccessCodeChange}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();  
                    handleAccessCodeSubmit();  
                  }
                }}
              />
              <Button variant="contained" color="primary" onClick={handleAccessCodeSubmit}>
                Submit
              </Button>
            </FormGroup>
          </Box>
        )}
        {showSignUp && tabValue === 0 && (
          <Box p={3}>
            <Typography variant="h6" gutterBottom>
                Signup for {courseData["courseId"]} as {courseData["isAdmin"] ? "an instructor" : "a student"}
            </Typography>
            <FormGroup>
              <TextField
                label="Username"
                type="text"
                fullWidth
                variant="outlined"
                margin="normal"
                value={username}
                onChange={onUsernameChange}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();  
                    onSignup();  
                  }
                }}
              />
              {usernameValidationMessage && <Typography color="error">{usernameValidationMessage}</Typography>}
              <TextField
                label="Password"
                type="password"
                fullWidth
                variant="outlined"
                margin="normal"
                value={password}
                onChange={onPasswordChange}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();  
                    onSignup();  
                  }
                }}
              />
              <TextField
                label="Confirm Password"
                type="password"
                fullWidth
                variant="outlined"
                margin="normal"
                value={confirmPassword}
                onChange={onConfirmPasswordChange}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();  
                    onSignup();  
                  }
                }}
              />
              {passwordValidationMessage && <Typography color="error">{passwordValidationMessage}</Typography>}
              <Typography>
                Already have an account? Login and use the account button to enroll in your course!
              </Typography>
              <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={onSignup}>
                Signup
              </Button>
            </FormGroup>
          </Box>
        )}
        {tabValue === 1 && (
          <Box p={3}>
            <Typography variant="h6" gutterBottom>
              Login
            </Typography>
            <FormGroup>
              <TextField
                label="Username"
                type="text"
                fullWidth
                variant="outlined"
                margin="normal"
                value={username}
                onChange={onUsernameChange}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();  
                    onLogin();  
                  }
                }}
              />
              <TextField
                label="Password"
                type="password"
                fullWidth
                variant="outlined"
                margin="normal"
                value={password}
                onChange={onPasswordChange}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();  
                    onLogin();  
                  }
                }}
              />
              <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={onLogin}>
                Login
              </Button>
            </FormGroup>
          </Box>
        )}
      </Box>
    </Modal>
  );
}

  export default LoginModal;