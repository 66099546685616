import CloseIcon from "@mui/icons-material/Close";

import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useGetLiveChatMessages } from "../../hooks/useGetLiveChatMessages";
import { useLiveChat } from "../../hooks/useLiveChat";

export const SubmitFeedbackModal = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const { sendChatMessage, messages, setMessages, connect, disconnect } =
    useLiveChat({
      user,
    });
  const { data, loading, refetch } = useGetLiveChatMessages(user.userId);
  const messageEndRef = useRef(null);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, messages, refetch]);

  const handleOpen = () => {
    connect();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    disconnect();
  };

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (!message.trim()) return;

    sendChatMessage({ text: message });
    setMessage("");
  };

  useEffect(() => {
    if (!loading && data) {
      setMessages(data);
    }
  }, [loading, data, setMessages]);

  useEffect(() => {}, [messages]);

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={handleOpen}
        startIcon={<SendIcon />}
        sx={{
          fontWeight: "bold",
          borderRadius: 2,
          boxShadow: 1,
          "&:hover": {
            boxShadow: 3,
            transform: "scale(1.05)",
            transition: "all 0.3s ease",
          },
        }}
      >
        Need Help?
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Live Chat
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column-reverse", // This line is new
              overflowY: "auto",
              maxHeight: 300,
              marginBottom: 2,
              backgroundColor: "#f0f0f0",
              padding: 2,
            }}
          >
            {messages
              .slice()
              .reverse()
              .map(
                (
                  msg,
                  index // Reverse the array here for rendering
                ) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: msg.fromUser ? "flex-end" : "flex-start",
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        background: msg.fromUser ? "#ffccbc" : "#e0f2f1",
                        borderRadius: "10px",
                        padding: "8px",
                        maxWidth: "80%",
                      }}
                    >
                      {msg.text}
                    </Typography>
                  </Box>
                )
              )}
          </Box>

          <Box component="form" onSubmit={handleSendMessage} noValidate>
            <TextField
              fullWidth
              variant="outlined"
              label="Type your message here"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              margin="normal"
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
              sx={{ marginTop: 1 }}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
