import { useMutation, useQueryClient } from "react-query";

const fetchSummary = async (courseId, startDate, endDate) => {
  const payload = {
    method: "getSummariesByCourse",
    courseId: courseId,
    startDate: startDate,
    endDate: endDate,
  };
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_API}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    }
  );

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Server responded with status: ${response.status}`);
  }
};

const fetchDigestedSummary = async (key) => {
  const payload = {
    method: "getDigestedSummaryRequest",
    key: key,
  }; //fill in payload probably {course, startDate, endDate, summaryType}
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_API}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    }
  );

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Server responded with status: ${response.status}`);
  }
};

export const useFetchSummaries = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: getSummariesByCourse } = useMutation(fetchSummary, {
    onSuccess: (data) => {
      // Cache the digested summaries key and summaries
      queryClient.setQueryData("summaries", data.summaries);
      queryClient.setQueryData(
        "digestedSummariesKey",
        data.digestedSummariesKey
      );
    },
  });

  const { mutateAsync: getDigestedSummary } = useMutation(
    fetchDigestedSummary,
    {
      onSuccess: (data) => {
        // Cache the executive summary and digested summaries
        queryClient.setQueryData("executiveSummary", data.executiveSummary);
        queryClient.setQueryData("digestedSummaries", data.digestedSummaries);
      },
    }
  );

  return {
    getSummariesByCourse,
    getDigestedSummary,
  };
};
