import React from 'react';
import {CookieConsent as ReactCookieConsent} from "react-cookie-consent";

const CookieConsent = () => {
    return (
        <div>
        <ReactCookieConsent
            location="bottom"
            buttonText="I understand"
            cookieName="responsibleAiCookie"
            style={{ background: "#2B373B", zIndex: 10000 }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
        >
          <p style={{"color": "white", "fontSize": "14px"}}>
            Tailored Tutor utilizes GPT-4 technology to provide assistance. 
            While we strive for accuracy, there may be occasional <a 
              href="https://en.wikipedia.org/wiki/Hallucination_(artificial_intelligence)" 
              style={{"color": "yellow", "textDecoration": "underline"}}
              target="_blank"
              rel="noopener noreferrer"
              >
              errors
            </a>. 
            Remember, this tool is an aid and not a substitute for your course materials and instructors. Please verify any critical information with your course instructor, who is the final authority on course content.
          </p>
        </ReactCookieConsent>
      </div>
    );
  };
  
  export default CookieConsent;
  

