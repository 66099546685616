import { useMutation } from "react-query";
import { useRef } from "react";

/** Fetches the user by it's id
 *
 * @param {credentialResponse}
 *    @credentialResponse what Google OAuth client has returned to us regarding the user's credentials
 * @returns {mutation, data}
 *    @mutation the mutated hook to be used to retrieve messages that calls the fetch and returns.
 *    @data returns a boolean on whether the response was successful or not
 *
 */

const googleSignIn = async (credentialResponse) => {
  const payload = {
      method: "signInUser",
      token: credentialResponse.credential,
      clientId: credentialResponse.clientId,
  }
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_API}/users/googleSignIn`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    }
  );

  const loginResponse = await response.json();
  console.log(loginResponse);

  if (response.status === 200) {
    return loginResponse;
  } else {
    throw new Error(`Server responded ${loginResponse} with status: ${response.status}`);
  }
};

export const useGoogleSignIn = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation((credentialResponse) => googleSignIn(credentialResponse), {
    onSettled: (data, error) => {
      if (resolveMutation.current) {
        if (error) {
          resolveMutation.current.reject({ error });
        } else {
          resolveMutation.current.resolve({ data });
        }
      }
    },
  });

  const googleSignInWithPromise = (credentialResponse) => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate(credentialResponse);
    });
  };

  return { ...mutation, googleSignIn: googleSignInWithPromise };
};