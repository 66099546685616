import { useMutation } from "react-query";
import { useRef } from "react";

/**
 * Handles the creation of a new thread.
 *
 * @param {string} userId - The ID of the user creating the thread.
 * @param {string} courseId - The ID of the course for which the thread is being created.
 * @returns {boolean} - Returns true if the thread creation is successful, otherwise false.
 */

const createThread = async (userId, courseId) => {
  const payload = {
    userId: userId,
    courseId: courseId
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/threads/createThread`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

  if (response.status !== 200) {
    throw new Error(`Server responded with status: ${response.status}`);
  }
  const createThreadResponse = await response.json();
  console.log("createThreadResponse: ", createThreadResponse)
  if (createThreadResponse.statusCode !== 200) {
    throw new Error(`Failed to create thread: ${createThreadResponse.response}`);
  }
  return createThreadResponse.response; // returns the threadResponse
  } catch (error) {
    console.error("There was an error creating the thread:", error);
  }
  return {};
};

export const useCreateThread = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation(([userId, courseId]) => createThread(userId, courseId), {
    onSettled: (data, error) => {
      if (resolveMutation.current) {
        if (error) {
          resolveMutation.current.reject({ error });
        } else {
          resolveMutation.current.resolve({ data });
        }
      }
    },
  });

  const createThreadWithPromise = (userId, courseId) => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate([userId, courseId]);
    });
  };

  return { ...mutation, createThread: createThreadWithPromise };
};