import { useMutation } from "react-query";

/**
 * Check if an accessCode is associated with a course.
 * 
 * @param {String} accessCode the accessCode to validate 
 * @returns if the accessCode is valid, it will return the course that the accessCode corresponds to. otherwise it throws an error.
 */

const validateAccessCode = async (accessCode) => {
  try {
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/courses/validateAccessCode/${accessCode}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" }
        }
    );

    console.log("validateAccessCode response: ", response);

    if (response.status === 200) {
        const validateAccessCodeResponse = await response.json();
        if (validateAccessCodeResponse.statusCode === 404) {
            throw new Error(`Invalid access code: ${accessCode}`);
        } 
        if (validateAccessCodeResponse.statusCode !== 200) {
            throw new Error(`Failed to validate access code: ${accessCode}`);
        }
        const accessCodeDetails = validateAccessCodeResponse.response;
        console.log("validateAccessCode courseDetails: ", accessCodeDetails);
        console.log("validateAccessCode courseDetails courseId: ", accessCodeDetails['courseId']);
        console.log("validateAccessCode courseDetails remainingUses: ", accessCodeDetails['remainingUses']);
        if (accessCodeDetails['courseId'] === "") {
            throw new Error("Invalid access code")
        }
        if (accessCodeDetails['remainingUses'] === '0') {
            throw new Error("No usages remaining for this access code, please contact your instructor for a new one.")
        }
      return accessCodeDetails;
    } else {
      console.log(response);
      throw new Error("A backend server error occurred");
    }
  } catch (error) {
    console.error("Error validating access code:", error);
    throw error;
  }
};

export const useValidateAccessCode = ( 
  setCourseData, 
  setShowSignup 
) => {
  const mutation = useMutation(
    ({ accessCode }) => validateAccessCode(accessCode), 
    {
      onSuccess: (data) => {
        setCourseData(data);
        setShowSignup(true);
      },
      onError: (error) => {
        alert(error);
      },
  });

  return mutation;
};
