import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';

const StartQuizModal = ({ open, onClose, onStartQuizClick }) => {
  const [topic, setTopic] = useState('');
  const [questionCount, setQuestionCount] = useState(5);
  const [answerType, setAnswerType] = useState('multiple choice');
  const [error, setError] = useState('error');

  const [isGenerating, setIsGenerating] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (topic.trim()) {
        setIsGenerating(true); // Set loading to true
        try {
          await onStartQuizClick(topic.trim(), questionCount, answerType);
        } catch (error) {
          console.error('Failed to start quiz:', error);
        } finally {
          setIsGenerating(false); // Set loading to false
          handleClose(); // Close the modal after submitting
        }
    } else {
        setError(true);
    }
  };

  const handleClose = () => {
    onClose();
    setTopic('');
    setQuestionCount(5);
    setAnswerType('multiple choice');
  }

  return (
    <Dialog open={open} onClose={!isGenerating ? handleClose : undefined}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Start a New Quiz</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To start a new quiz, please select the topic, number of questions, and answer type.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="topic"
            label="Topic"
            type="text"
            fullWidth
            variant="outlined"
            value={topic}
            onChange={e => setTopic(e.target.value)}
            disabled={isGenerating} // Disable the input when generating
          />
          <TextField
            margin="dense"
            id="question-count"
            label="Number of Questions"
            type="number"
            fullWidth
            variant="outlined"
            value={questionCount}
            onChange={e => setQuestionCount(Number(e.target.value))}
            disabled={isGenerating} // Disable the input when generating
          />
          <FormControl fullWidth margin="dense" disabled={isGenerating}>
            <InputLabel id="answer-type-label">Answer Type</InputLabel>
            <Select
              labelId="answer-type-label"
              id="answer-type"
              value={answerType}
              label="Answer Type"
              onChange={e => setAnswerType(e.target.value)}
              disabled={isGenerating} // Disable the select when generating
            >
              <MenuItem value={'multiple choice'}>Multiple Choice</MenuItem>
              <MenuItem value={'free response'}>Free Response</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isGenerating}>Cancel</Button>
          <Button type="submit" color="primary" disabled={isGenerating}>
            {isGenerating ? "Generating..." : "Start Quiz"}
          </Button>
          {isGenerating && (
            <CircularProgress
              size={48}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default StartQuizModal;
