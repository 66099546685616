import * as d3 from "d3";
import React, { useEffect, useRef } from "react";

const LineChart = ({ data, startDate, endDate }) => {
  const d3Container = useRef(null);

  useEffect(() => {
    if (data && d3Container.current) {
      const margin = { top: 20, right: 30, bottom: 30, left: 40 };
      const width = 920 - margin.left - margin.right;
      const height = 400 - margin.top - margin.bottom;

      // Clear the container each time the component updates
      d3.select(d3Container.current).selectAll("*").remove();

      const svg = d3
        .select(d3Container.current)
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Generate date points between startDate and endDate, one hour apart
      const timeScale = d3
        .scaleTime()
        .domain([new Date(startDate), new Date(endDate)])
        .range([0, width]);

      const timePoints = d3.timeHour.range(
        new Date(startDate),
        new Date(endDate)
      );

      // Pair each data point with its corresponding time point
      const plottedData = data.map((value, index) => ({
        date: timePoints[index % timePoints.length],
        value,
      }));

      // Add X axis
      svg
        .append("g")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(timeScale));

      // Add Y axis
      const y = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => +d)])
        .range([height, 0]);
      svg.append("g").call(d3.axisLeft(y));

      // Add the line
      svg
        .append("path")
        .datum(plottedData)
        .attr("fill", "none")
        .attr("stroke", "steelblue")
        .attr("stroke-width", 1.5)
        .attr(
          "d",
          d3
            .line()
            .x((d) => timeScale(d.date))
            .y((d) => y(d.value))
        );
    }
  }, [data, startDate, endDate]);

  return <div className="d3-component" ref={d3Container} />;
};

export default LineChart;
