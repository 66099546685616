// ThemeContext.js
import React, { createContext, useState, useContext } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { regularTheme, adminTheme } from './theme';

const ThemeContext = createContext();

export const useThemeContext = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [themeName, setThemeName] = useState('regular');

  // Logic to toggle theme name
  const toggleTheme = () => {
    setThemeName(themeName === 'regular' ? 'admin' : 'regular');
  };

  // Select the correct theme object based on the theme name
  const theme = themeName === 'regular' ? regularTheme : adminTheme;

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <MuiThemeProvider theme={theme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;

