import EventIcon from "@mui/icons-material/Event";
import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

export const DateRangePickerPopover = ({ handleDateChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const lastWeekStart = new Date();
  lastWeekStart.setDate(lastWeekStart.getDate() - lastWeekStart.getDay() - 7);
  lastWeekStart.setHours(0, 0, 0, 0);
  const lastWeekEnd = new Date(lastWeekStart);
  lastWeekEnd.setDate(lastWeekEnd.getDate() + 6);
  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      month: "short", // "Jun"
      day: "2-digit", // "01"
      year: "numeric", // "2023"
    });
  };

  const [displayStartDate, setDisplayStartDate] = useState(
    formatDate(lastWeekStart)
  );
  const [displayEndDate, setDisplayEndDate] = useState(formatDate(lastWeekEnd));

  const [state, setState] = useState([
    {
      startDate: lastWeekStart,
      endDate: lastWeekEnd,
      key: "selection",
    },
  ]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (ranges) => {
    setState([ranges.selection]);
    setDisplayStartDate(formatDate(ranges.selection.startDate));
    setDisplayEndDate(formatDate(ranges.selection.endDate));
  };

  const handleSubmit = () => {
    handleDateChange(state[0].startDate, state[0].endDate);

    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Typography
        variant="h6"
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          color: "white",
        }}
      >
        Current Date Range: {displayStartDate} - {displayEndDate}
      </Typography>
      <IconButton
        onClick={handleClick}
        sx={{ color: "#3498db" }}
        aria-describedby={id}
        aria-label="pick date range"
      >
        <EventIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            backgroundColor: "background.paper",
            p: 2,
            borderRadius: 1,
            maxWidth: 575,
            width: "100%",
            position: "relative",
          }}
        >
          <Typography variant="h6" mb={2}>
            Select Date Range
          </Typography>
          <DateRangePicker
            onChange={handleSelect}
            showSelectionPreview={true}
            showDateDisplay={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="vertical"
          />
          <Box sx={{ position: "absolute", top: 8, right: 8 }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
