import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  CircularProgress,
  Card,
  CardContent,
  Button
} from "@mui/material";
import logo_transparent from "../../assets/images/logo_transparent.png";
import SignInBG from "../../assets/images/SignInBG.png";
import { useMobile } from "../../contexts/MobileContext";
import { useValidateAccessCode } from "../../hooks/useValidateAccessCode";
import LoginModal from "../LoginModal/LoginModal";
import { useAccessCodeSignup } from "../../hooks/useAccessCodeSignup";
import { usePasswordLogin } from "../../hooks/usePasswordLogin";

const LoginPage = ({ onLoginSuccess, onPasswordLoginSuccess, authenticating, theme }) => {
  const clientId =
    "95431639768-csj0ggpva66alba5t5k5tct4d6nhe4s0.apps.googleusercontent.com";

  const styles = {
    pageContainer: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: `url(${SignInBG})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
  };
  const [loading, setLoading] = useState(true);
  const isMobile = useMobile().isMobile;
  const [modalOpen, setModalOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0)
  const [showSignUp, setShowSignUp] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const [accessCode, setAccessCode] = useState('');
  const [courseData, setCourseData] = useState(null);
  const handleCloseModal = () => setModalOpen(false);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const validateAccessCode = useValidateAccessCode(setCourseData, setShowSignUp);

  const handleAccessCodeSubmit = () => {
    validateAccessCode.mutate({accessCode: accessCode});
  };

  const handleOpenSignUpModal = () => {
    setModalOpen(true);
    setShowSignUp(false);
    setTabValue(0); // Assume 0 is for signup
  };
  const handleOpenLoginModal = () => {
    setModalOpen(true);
    setShowSignUp(false);
    setTabValue(1); // Assume 1 is for login
  };
  const accessCodeSignup = useAccessCodeSignup(onPasswordLoginSuccess);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('')

  const handleAccessCodeSignup = () => {
    if (password === confirmPassword) {
      accessCodeSignup.mutate({accessCode: accessCode, username: username, password: password});
    } else {
      alert("passwords do not match!");
    }
  };

  const passwordLogin = usePasswordLogin(onPasswordLoginSuccess);

  const handlePasswordLogin = () => {
    passwordLogin.mutate({username: username, password: password});
  };

  useEffect(() => {
    const initGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: onLoginSuccess,
        scope: "email",
      });

      window.google.accounts.id.renderButton(
        document.getElementById("signInDiv"),
        {
          theme: "outline",
          size: "medium",
          shape: "pill",
          width: "100%",
        } // Removed width to handle with CSS
      );
      setLoading(false);
    };

    if (window.google) {
      initGoogleSignIn();
    } else {
      window.onload = () => initGoogleSignIn();
    }
  }, []);

  const handleAccessCodeChange = (event) => {
    setAccessCode(event.target.value);
  };
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  return (
    <div style={styles.pageContainer}>
      <Card
        variant="outlined"
        sx={{
          width: isMobile ? "55%" : 275,
          textAlign: "center",
          boxShadow: "0px 4px 8px 15px rgba(50,50,50,0.4)",
          position: "relative",
          p: isMobile ? 1 : 3,
          zIndex: 1,
        }}
      >
        <CardContent>
          <Typography
            component="h1"
            variant={isMobile ? "h5" : "h4"}
            gutterBottom
            sx={{
              color: theme.palette.primary.main,
              fontFamily: "initial",
              position: "relative",
            }}
          >
            Welcome to Tailored Tutor
          </Typography>
          <img
            src={logo_transparent}
            alt="Tailored Tutor logo"
            s
            style={{
              width: "50px",
              height: "50px",
            }}
          />
          {(authenticating || loading) && (
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <CircularProgress />
            </Box>
          )}
          <Box
            my={4}
            id="signInDiv"
            sx={{
              display: authenticating ? "none" : "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Google Sign-In button will be rendered here */}
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenSignUpModal}
          >
            Signup with Access Code
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenLoginModal}
            sx={{ mt: 2 }}
          >
            Login
          </Button>
          <LoginModal
            modalOpen={modalOpen}
            handleCloseModal={handleCloseModal}
            tabValue={tabValue}
            handleTabChange={handleTabChange}
            showSignUp={showSignUp}
            accessCode={accessCode}
            handleAccessCodeChange={handleAccessCodeChange}
            handleAccessCodeSubmit={handleAccessCodeSubmit}
            courseData={courseData}
            handleAccessCodeSignup={handleAccessCodeSignup}
            handlePasswordLogin={handlePasswordLogin}
            username={username}
            password={password}
            confirmPassword={confirmPassword}
            handleUsernameChange={handleUsernameChange}
            handlePasswordChange={handlePasswordChange}
            handleConfirmPasswordChange={handleConfirmPasswordChange}
          />
        </CardContent>
      </Card>
      {/* Additional login content, if any */}
    </div>
  );
};

export default LoginPage;
