import { useMutation } from "react-query";
import { useRef } from "react";

/**
 * Handles sending a userMessage in a thread and streaming the response back to the user.
 *
 * @param {string} threadId - The ID of the thread to send the message in.
 * @param {string} userMessage - The message to send in the thread.
 * @param {websocket} websocket - The websocket to use for streaming the response.
 * @returns {boolean} - Returns a boolean whether the response finished successfully or not.
 */

const streamAssistantResponse = async (threadId, userMessage, ws) => {

  console.log("streamAssistantResponse threadId: ", threadId)
  console.log("streamAssistantResponse userMessage: ", userMessage)
  console.log("streamAssistantResponse ws: ", ws)
  // validate that the websocket exists and is open
  if (!ws || ws.readyState !== WebSocket.OPEN) {
    console.error("Websocket connection is not open.");
    return;
  }

  const payload = {
    threadId: threadId,
    userMessage: userMessage,
    websocketId: ws.url.split("?wsId=")[1],
  };
  console.log("streamAssistantResponse payload: ", payload)
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/assistants/streamAssistantResponse`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    if (response.status !== 200) {
      throw new Error(`Server responded with status: ${response.status}`);
    }
    const streamAssistantResponse = await response.json();
    console.log("streamAssistantResponse: ", streamAssistantResponse)
    if (streamAssistantResponse.statusCode !== 200) {
      throw new Error(`Failed to stream response: ${streamAssistantResponse.response}`);
    }
    return streamAssistantResponse.response; // returns the threadResponse
  } catch (error) {
    console.error("There was an error streaming the response:", error);
  }
  return {};
};

export const useStreamAssistantResponse = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation(([threadId, userMessage, ws]) => streamAssistantResponse(threadId, userMessage, ws), {
    onSettled: (data, error) => {
      if (resolveMutation.current) {
        if (error) {
          resolveMutation.current.reject({ error });
        } else {
          resolveMutation.current.resolve({ data });
        }
      }
    },
  });

  const streamAssistantResponseWithPromise = (threadId, userMessage, ws) => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate([threadId, userMessage, ws]);
    });
  };

  return { ...mutation, streamAssistantResponse: streamAssistantResponseWithPromise };
};