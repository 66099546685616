import { Box, List, ListItem, Paper, Skeleton } from "@mui/material";
import React from "react";

const ChatWindowSkeleton = ({ isAdmin }) => {
  const chatMessagesSkeleton = (
    <List sx={{ overflowY: "auto", flexGrow: 1 }}>
      {[...Array(6)].map((_, index) => (
        <ListItem
          key={index}
          sx={{
            display: "flex",
            justifyContent: "center", // Skeletons appear centered
            width: "100%",
          }}
        >
          <Paper
            elevation={1}
            sx={{
              bgcolor: index % 2 === 0 ? "background.paper" : "primary.light",
              margin: 1,
              maxWidth: "60%",
              padding: 1,
              width: index % 2 === 0 ? "100%" : "40%",
              marginLeft: index % 2 === 0 ? "0" : "10%",
              marginRight: index % 2 === 0 ? "10%" : "0",
            }}
          >
            <Skeleton variant="text" width="100%" height={24} />
            <Skeleton variant="text" width="80%" height={24} />
            <Skeleton variant="text" width="90%" height={24} />
          </Paper>
        </ListItem>
      ))}
    </List>
  );

  const messageInputAreaSkeleton = (
    <Box
      sx={{
        padding: 5,
        display: "flex",
        alignItems: "center",
        bottom: 0,
        bgcolor: "background.paper",
      }}
    >
      <Skeleton variant="rectangular" width="100%" height={80} />
    </Box>
  );

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {chatMessagesSkeleton}
      {!isAdmin && messageInputAreaSkeleton}
    </Box>
  );
};

export default ChatWindowSkeleton;
