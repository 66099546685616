import { useMutation } from "react-query";
import { useRef } from "react";

/** Gets the users enrolled in a course
 *
 * This hook provides an abstraction for getting users that are enrolled in a course.
 *
 * @param {courseId}
 *    @user the course table identifier
 * @returns {mutation,data}
 *    @mutation the mutated hook to be used to retrieve courses.
 *    @data list of enrolled users as a list of strings of userIds
 */

const getUsersInCourse = async (courseId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/courses/getUsersInCourse/${courseId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      }
    );

    const getUsersInCourseResponse = await response.json();
    console.log(getUsersInCourseResponse);
    if (response.status === 200) {
      return getUsersInCourseResponse;
    } else {
      return false;
    }
  } catch (error) {
    console.error(`There was an error getting users for course: ${courseId}. Error: ${error}`);
  }
  return {};
};

export const useGetUsersByCourse = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation((courseId) => getUsersInCourse(courseId), {
    onSettled: (data, error) => {
      if (resolveMutation.current) {
        if (error) {
          resolveMutation.current.reject({ error });
        } else {
          resolveMutation.current.resolve({ data });
        }
      }
    },
  });

  const getUsersByCourseWithPromise = (courseId) => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate(courseId);
    });
  };

  return { ...mutation, getUsersByCourse: getUsersByCourseWithPromise };
};