export const fetchCourseAnalytics = async (courseId, startDate, endDate) => {
  try {
    const response = await fetch(
      "https://ehay8rhw9k.execute-api.us-east-1.amazonaws.com/prod/getCourseAnalytics",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          courseId,
          startDate,
          endDate,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There was an error fetching the course analytics", error);
  }
};
